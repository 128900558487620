.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Content {
  background: linear-gradient(to bottom, rgba(87, 158, 156, 0.28) 0%, rgba(87, 158, 156, 0.61) 60%, rgba(87, 158, 156, 0.83) 100%);
  height: 100vh;
  color: #33575F;
  font-size: 16px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Content header {
  position: absolute;
  top: 20px;
  left: 20px;
}

.Content img {
  /*margin: 15px;*/
  height: 60px;
}

.Content main {
  width: 650px;
}

.Content form label {
  font-weight: bold;
}

.Content form button {
  border-radius: 15px;
  color: #fff;
  background-color: #33575F;
  border: none;
  padding: 5px 10px;
  transition: all 1s ease-in-out;
}

.Content form button:hover {
  background-color: #568e9a;
  transform: scale(1.05);
}

.Content footer {
  position: absolute;
  bottom: 20px;
  left: 20px;
  font-size: 20px;
  font-weight: bold;
}